<template>
  <div class="order-base__warp" style="padding-bottom: 50px">
    <div class="erp-form-bar bar-sticky">
      <div class="erp-primary__btn" @click="editCardInfo" v-if="(data.status == 1 || data.status == 4) && hasPermission('erp:order:editXhfwOrder')">编辑</div>
      <div class="erp-primary__btn plain" @click="finishOrder" v-if="data.status == 1 && hasPermission('erp:order:closeOpenOrderXhfw')">完结订单</div>
      <div class="erp-primary__btn plain" @click="closeOrder(1)" v-if="data.status == 4 && hasPermission('erp:order:closeOpenOrderXhfw')">关闭订单</div>
      <div
        class="erp-primary__btn plain"
        @click="reStoreOrder(data.status)"
        v-if="(data.status == 2 || data.status == 3) && hasPermission('erp:order:closeOpenOrderXhfw')"
      >
        还原
      </div>
      <div class="erp-default__btn" @click="back">返回</div>
    </div>
    <!-- 55等分布局 -->
    <el-row type="flex" class="op-16" :gutter="80">
      <el-col :span="12">
        <div class="erp-form__title f16">订单信息</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view label="销售合同号" :value="data.businessContractNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="客户" :value="data.customerName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="上游名称" :value="data.xhfwUpstreamName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="订单状态" :value="data.statusDisplay"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="冷库" :value="data.storageName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="柜号" :value="data.containerNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="保证金比例(%)" :value="data.depositRate"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="资金方" :value="data.fundingShortName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="抬头" :value="data.consigneeShortName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="单证跟单员" :value="data.userNameDoc"></erp-view>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="12"></el-col>
    </el-row>
    <div style="margin-top: 50px" class="order-title__bar op-16">
      <div class="erp-form__title f16">证件</div>
    </div>
    <el-table :data="attachmentList" border class="egrid erp-table__list erp-view__table">
      <el-table-column label="证件类型" prop="attachmentTypeDisplay" align="center" show-overflow-tooltip width="240"></el-table-column>
      <el-table-column label="文件名" prop="name" align="center" show-overflow-tooltip>
        <template v-slot="scope">
          <div class="card-preview" style="justify-content: center">
            <span>{{ scope.row.name }}</span>
            <img src="../../../../../assets/images/preview__zoom.png" alt @click="$showFiles(scope.row.name, scope.row.url)" />
            <img src="../../../../../assets/images/preview_download.png" alt @click="$showFiles(scope.row.name, scope.row.url, false)" />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="上传人" prop="creator" align="center" show-overflow-tooltip width="180"></el-table-column>
      <el-table-column label="上传时间" prop="createdTime" align="center" show-overflow-tooltip width="180"></el-table-column>
    </el-table>
    <Pagination
      ref="pcPagination"
      url="/malicrm/order/queryOrderQhdcAttachmentListWithPage"
      :queryData="dataQueryForm"
      @change="queryCallBackFn"
      :showTotal="10"
      :isSimple="false"
    >
    </Pagination>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/Pagination.vue';
import { httpPost } from '@/api';
import { erpConfirm, errorTip, getRouterParams, successTip } from '@/utils/publicMethods';
export default {
  components: { Pagination },
  props: {
    data: {
      // 获取传递过来的详情
      type: [Object],
      default: function () {
        return {
          id: 0,
          ladingBillNo: null, // 提单号
          orderId: 0,
          orderVersion: 0,
          shippingEndDate: null, // 到港日
          supplierContractNo: null, // 供应商合同号
          version: 0,
        };
      },
    },
  },
  data() {
    return {
      dataQueryForm: {
        pageSize: 10,
        pageNum: 1,
        orderId: this.data.orderId,
      },
      attachmentList: [],
      isShow: false,
    };
  },
  methods: {
    finishOrder() {
      erpConfirm('确定需要完结当前订单吗？完结后的订单不可再做修改。').then(async () => {
        const res = await httpPost('/malicrm/order/finishOrder', {
          orderId: this.data.orderId,
        });
        if (res.code === 200) {
          this.$emit('back', 1);
          successTip('已完结订单');
        } else {
          errorTip(res.message);
        }
      });
    },
    reOpenOrder() {
      erpConfirm('确定要恢复当前订单状态为进行中吗？').then(async () => {
        const res = await httpPost('/malicrm/order/reOpenOrder', {
          orderId: this.data.orderId,
        });
        if (res.code === 200) {
          this.$emit('back', 1);
          successTip('已恢复订单');
        } else {
          errorTip(res.message);
        }
      });
    },
    reStoreOrder(status) {
      // 还原判断
      erpConfirm(status === 2 ? '确定要将当前订单还原为“已取消”的状态吗？' : '确定要将当前订单还原为“进行中”的状态吗？')
        .then(async () => {
          const url = status === 2 ? '/malicrm/order/closeOpenOrderQhdc' : '/malicrm/order/reOpenOrder';
          const data = {
            id: this.data.id,
            operType: status,
            orderId: this.data.orderId,
            orderVersion: this.data.orderVersion,
            version: this.data.version,
          };
          const res = await httpPost(url, data);
          if (res.code === 200) {
            successTip('已还原订单');
            this.$emit('back', 1);
          } else {
            errorTip(res.message);
          }
        })
        .catch(() => {
          return false;
        });
    },
    closeOrder(status) {
      // 关闭还原订单
      erpConfirm('确定需要关闭当前订单吗？关闭后的订单不可再做修改。')
        .then(async () => {
          const res = await httpPost('/malicrm/order/closeOpenOrderQhdc', {
            id: this.data.id,
            operType: status,
            orderId: this.data.orderId,
            orderVersion: this.data.orderVersion,
            version: this.data.version,
          });
          if (res.code === 200) {
            successTip('已关闭订单');
            this.$emit('back', 1);
          } else {
            errorTip(res.message);
          }
        })
        .catch(() => {
          return false;
        });
    },
    queryCallBackFn(res) {
      this.attachmentList = res;
    },

    back() {
      const { orderId, name, version } = getRouterParams('OrderSpotDetail');
      this.$router.push({
        name: name,
        params: {
          id: orderId,
          version: version,
        },
      });
    },
    editCardInfo() {
      this.$emit('edit', 4);
    },
  },
};
</script>

<style lang="scss" scoped>
.order-title__bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
}
.count-value {
  font-size: 14px;
  color: #303133;
}
.count-name {
  font-size: 14px;
  color: #606266;
}
</style>
