<template>
  <div class="order-base__warp" style="padding-bottom: 50px">
    <div class="erp-form-bar bar-sticky">
      <div class="erp-primary__btn" @click="editBaseInfo" v-if="(data.status == 1 || data.status == 4) && hasPermission('erp:order:editXhfwOrder')">编辑</div>
      <div class="erp-primary__btn plain" @click="finishOrder" v-if="data.status == 1 && hasPermission('erp:order:closeOpenOrderXhfw')">完结订单</div>
      <div class="erp-primary__btn plain" @click="closeOrder(1)" v-if="data.status == 4 && hasPermission('erp:order:closeOpenOrderXhfw')">关闭订单</div>
      <div
        class="erp-primary__btn plain"
        @click="reStoreOrder(data.status)"
        v-if="(data.status == 2 || data.status == 3) && hasPermission('erp:order:closeOpenOrderXhfw')"
      >
        还原
      </div>
      <div class="erp-default__btn" @click="back">返回</div>
    </div>
    <!-- 55等分布局 -->
    <el-row :gutter="80" type="flex" class="op-16">
      <el-col :span="12">
        <div class="erp-form__title f16">订单信息</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view label="销售合同号" :value="data.businessContractNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="客户" :value="data.customerName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="签约主体" :value="data.contractPrincipalName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="订单状态" :value="data.statusDisplay"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="上游名称" :value="data.xhfwUpstreamName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="冷库" :value="data.storageName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="柜号" :value="data.containerNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="资金方" :value="data.fundingShortName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="抬头" :value="data.consigneeShortName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="保证金比例(%)" :value="data.depositRate"></erp-view>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="12">
        <div class="erp-form__title f16">负责人</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view label="单证跟单员" :value="data.userNameDoc"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="资管跟单员" :value="data.userNameFund"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="客户经理" :value="data.clientManager"></erp-view>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!--订单产品信息展示-->
    <div style="margin-top: 50px" class="order-title__bar op-16">
      <div class="erp-form__title f16">产品信息</div>
      <div class="erp-product__count">
        <span class="count-name">合同重量合计(kg)：</span>
        <span class="count-value" style="margin-right: 16px">{{ data.contractWeightSumDisplay }}</span>
        <span class="count-name">合同货值合计：</span>
        <span class="count-value">{{ data.contractValueSumDisplay }}</span>
      </div>
    </div>
    <el-table :data="data.productList" border class="egrid erp-table__list erp-view__table">
      <el-table-column label="大类" prop="categoryName" align="center" show-overflow-tooltip width="90">
        <template v-slot="scope">
          {{ filterName(scope.row.categoryName) }}
        </template>
      </el-table-column>
      <el-table-column label="国家" prop="countryName" align="center" show-overflow-tooltip width="120">
        <template v-slot="scope">
          {{ filterName(scope.row.countryName) }}
        </template>
      </el-table-column>
      <el-table-column label="厂号" prop="plantNo" align="center" show-overflow-tooltip width="120">
        <template v-slot="scope">
          {{ filterName(scope.row.plantNo) }}
        </template>
      </el-table-column>
      <el-table-column label="中文品名" prop="nameCn" align="center" show-overflow-tooltip>
        <template v-slot="scope">
          {{ filterName(scope.row.nameCn) }}
        </template>
      </el-table-column>
      <el-table-column label="英文品名" prop="nameEn" align="center" show-overflow-tooltip>
        <template v-slot="scope">
          {{ filterName(scope.row.nameEn) }}
        </template>
      </el-table-column>
      <el-table-column label="二级标准" prop="xhfwSubLevelStandard" align="center" show-overflow-tooltip width="150">
        <template v-slot="scope">
          {{ filterName(scope.row.xhfwSubLevelStandard) }}
        </template>
      </el-table-column>
      <el-table-column label="单价" prop="unitPriceDisplay" align="center" show-overflow-tooltip width="150">
        <template v-slot="scope">
          {{ filterName(scope.row.unitPriceDisplay) }}
        </template>
      </el-table-column>
      <el-table-column label="件数" prop="packageCountDesc" align="center" show-overflow-tooltip width="100">
        <template v-slot="scope">
          {{ filterName(scope.row.packageCountDesc) }}
        </template>
      </el-table-column>
      <el-table-column label="合同重量(kg)" prop="contractWeightDisplay" align="center" show-overflow-tooltip width="150">
        <template v-slot="scope">
          {{ filterName(scope.row.contractWeightDisplay) }}
        </template>
      </el-table-column>
      <el-table-column label="合同货值" prop="contractAmountDisplay" align="center" show-overflow-tooltip width="150">
        <template v-slot="scope">
          {{ filterName(scope.row.contractAmountDisplay) }}
        </template>
      </el-table-column>
    </el-table>
    <div class="op-16">
      <div class="erp-form__title f16" style="margin-top: 50px">订单备注</div>
      <erp-view type="txt" :value="data.remark"></erp-view>
    </div>
    <InputDialog ref="inputDialog" width="360px"></InputDialog>
  </div>
</template>

<script>
import { erpConfirm, errorTip, getRouterParams, successTip } from '@/utils/publicMethods';
import { httpPost } from '@/api';
import InputDialog from '@/components/InputDialog/InputDialog.vue';
export default {
  components: { InputDialog },
  props: {
    data: {
      // 获取传递过来的详情
      type: [Object],
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      totalWeight: 0, // 合同重量
      totalAmount: 0, // 合同货值
    };
  },
  methods: {
    finishOrder() {
      this.$refs.inputDialog.init('提示', '确定需要完结当前订单吗？完结后的订单不可再做修改。').then(async (val) => {
        const res = await httpPost('/malicrm/order/finishOrder', {
          orderId: this.data.orderId,
          finishDate: val,
        });
        if (res.code === 200) {
          this.$emit('back', 1);
          successTip('已完结订单');
        } else {
          errorTip(res.message);
        }
      });
    },
    reOpenOrder() {
      erpConfirm('确定要恢复当前订单状态为进行中吗？').then(async () => {
        const res = await httpPost('/malicrm/order/reOpenOrder', {
          orderId: this.data.orderId,
        });
        if (res.code === 200) {
          this.$emit('back', 1);
          successTip('已恢复订单');
        } else {
          errorTip(res.message);
        }
      });
    },
    reStoreOrder(status) {
      // 还原判断
      erpConfirm(status === 2 ? '确定要将当前订单还原为“已取消”的状态吗？' : '确定要将当前订单还原为“进行中”的状态吗？')
        .then(async () => {
          const url = status === 2 ? '/malicrm/order/closeOpenOrderQhdc' : '/malicrm/order/reOpenOrder';
          const data = {
            id: this.data.id,
            operType: status,
            orderId: this.data.orderId,
            orderVersion: this.data.orderVersion,
            version: this.data.version,
          };
          const res = await httpPost(url, data);
          if (res.code === 200) {
            successTip('已还原订单');
            this.$emit('back', 1);
          } else {
            errorTip(res.message);
          }
        })
        .catch(() => {
          return false;
        });
    },
    closeOrder(status) {
      // 关闭还原订单
      erpConfirm('确定需要关闭当前订单吗？关闭后的订单不可再做修改。')
        .then(async () => {
          const res = await httpPost('/malicrm/order/closeOpenOrderQhdc', {
            id: this.data.id,
            operType: status,
            orderId: this.data.orderId,
            orderVersion: this.data.orderVersion,
            version: this.data.version,
          });
          if (res.code === 200) {
            successTip('已关闭订单');
            this.$emit('back', 1);
          } else {
            errorTip(res.message);
          }
        })
        .catch(() => {
          return false;
        });
    },
    editBaseInfo() {
      this.$emit('edit', 1);
    },

    clickTag(url) {
      if (url) {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.click();
      }
    },
    back() {
      const { orderId, name, version } = getRouterParams('OrderSpotDetail');
      this.$router.push({
        name: name,
        params: {
          id: orderId,
          version: version,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.order-title__bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
}

.count-value {
  font-size: 14px;
  color: #303133;
}

.count-name {
  font-size: 14px;
  color: #606266;
}
</style>
