<template>
  <div class="order-base__warp" style="padding-bottom: 50px">
    <div class="erp-form-bar bar-sticky">
      <div class="erp-primary__btn" @click="saveFormData">保存</div>
      <div class="erp-default__btn" @click="back">返回</div>
    </div>
    <!-- 55等分布局 -->
    <el-row :gutter="80" type="flex" class="op-16">
      <el-col :span="12">
        <div class="erp-form__title f16">订单信息</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view label="销售合同号" :value="data.businessContractNo" type="edit">
              <el-input v-model="formData.businessContractNo" :disabled="true"></el-input>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="客户" :value="data.customerName" type="edit">
              <el-input v-model="formData.customerName" :disabled="true"></el-input>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="签约主体" :value="data.contractPrincipalName" type="edit">
              <el-input v-model="formData.contractPrincipalName" :disabled="true"></el-input>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="订单状态" type="edit">
              <el-select v-model="formData.status">
                <el-option label="进行中" :value="1"></el-option>
                <el-option label="已取消" :value="4"></el-option>
              </el-select>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="上游名称" :value="data.xhfwUpstreamName" type="edit">
              <el-input :model-value="formData.xhfwUpstreamName ? formData.xhfwUpstreamName : '-'" :disabled="true"></el-input>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="冷库" :value="data.storageName" type="edit">
              <el-input :model-value="formData.storageName ? formData.storageName : '-'" :disabled="true"></el-input>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="柜号" :value="data.containerNo" type="edit">
              <el-input :model-value="formData.containerNo ? formData.containerNo : '-'" :disabled="true"></el-input>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="资金方" :value="data.fundingShortName" type="edit">
              <el-input :model-value="formData.fundingShortName ? formData.fundingShortName : '-'" :disabled="true"></el-input>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="抬头" :value="data.consigneeShortName" type="edit">
              <el-input :model-value="formData.consigneeShortName ? formData.consigneeShortName : '-'" :disabled="true"></el-input>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="保证金比例(%)" :value="data.depositRate" type="edit">
              <el-input v-model="formData.depositRate" :disabled="true"></el-input>
            </erp-view>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="12">
        <div class="erp-form__title f16">负责人</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view label="单证跟单员" type="edit">
              <FormChooseMan
                ref="relevant"
                class="no-arrow"
                :defaultCreated="false"
                v-model="formData.userIdsDoc"
                v-model:name="formData.userNameDoc"
                clearable
                :selectAll="true"
                right="35vw"
                :iserp="false"
                :limt="10"
                :required="false"
                title="请选择单证跟单员"
              ></FormChooseMan>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="资管跟单员" type="edit">
              <el-input :model-value="formData.userNameFund ? formData.userNameFund : '-'" :disabled="true"></el-input>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="客户经理" type="edit" v-if="hasPermission('erp:order:saveOrderQhdcBaseInfoAll')">
              <FormChooseMan
                ref="clientManager"
                class="no-arrow"
                :defaultCreated="false"
                v-model="formData.userIdManagerList"
                v-model:name="formData.clientManager"
                clearable
                :selectAll="true"
                right="35vw"
                :iserp="false"
                :limt="5"
                :required="false"
                title="请选择客户经理"
              ></FormChooseMan>
            </erp-view>
            <erp-view label="客户经理" type="edit" v-else>
              <el-input v-model="formData.clientManager" :disabled="true"></el-input>
            </erp-view>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!--订单产品信息展示-->
    <div style="margin-top: 50px" class="order-title__bar op-16">
      <div class="erp-form__title f16">产品信息</div>
      <div class="erp-product__count">
        <span class="count-name">合同重量合计(kg)：</span>
        <span class="count-value" style="margin-right: 16px">{{ data.contractWeightSumDisplay }}</span>
        <span class="count-name">合同货值合计：</span>
        <span class="count-value">{{ data.contractValueSumDisplay }}</span>
      </div>
    </div>
    <el-table :data="formData.productList" border class="egrid erp-table__list erp-view__table">
      <el-table-column label="大类" prop="categoryName" align="center" show-overflow-tooltip width="90">
        <template #default="scope">
            <DictSelect
              @change="querycategoryName($event, scope.row)"
              v-model="scope.row.categoryName"
              name="goods_category_label"
              placeholder="请选择大类"
              :disable="isDisbale"
              v-if="!isDisbale"
            ></DictSelect>
            <span v-else>{{scope.row.categoryName}}</span>
            <!-- disable 不生效 -->
        </template>
      </el-table-column>
      <el-table-column label="国家" prop="countryName" align="center" show-overflow-tooltip width="120">
        <template #default="scope">
            <DictSelect
                @change="changecountry($event,scope.row)"
                @focus="querycountry(scope.row)"
                v-model="scope.row.countryName"
                :customOption="countryList"
                key-label="countryName"
                key-value="country"
                placeholder="请选择国家"
                :disable="isDisbale"
                v-if="!isDisbale"
            ></DictSelect>
            <span v-else>{{scope.row.countryName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="厂号" prop="plantNo" align="center" show-overflow-tooltip width="120">
        <template #default="scope">
          <el-select
            v-model="scope.row.plantNo"
            class="mali-full__input"
            clearable
            @change="changePlantNo($event, scope.row)"
            @focus="queryPlantNo($event, scope.row)"
            :disable="isDisbale"
             v-if="!isDisbale"
          >
            <el-option v-for="item in plantNoList" :key="item.value" :label="item" :value="item"></el-option>
          </el-select>
          <span v-else>{{scope.row.plantNo}}</span>
        </template>
      </el-table-column>
      <el-table-column label="中文品名" prop="nameCn" align="center" show-overflow-tooltip>
        <template #default="scope">
            <el-input v-if="!isDisbale" v-model="scope.row.nameCn" maxlength="20" :disable="!isDisbale"></el-input>
            <span v-else>{{scope.row.nameCn}}</span>
        </template>        
      </el-table-column>
      <el-table-column label="英文品名" prop="nameEn" align="center" show-overflow-tooltip>
        <template #default="scope">
            <el-input v-if="!isDisbale" v-model="scope.row.nameEn" maxlength="100" :disable="isDisbale"></el-input>
            <span v-else>{{scope.row.nameEn}}</span>
        </template>
      </el-table-column>
      <el-table-column label="二级标准" prop="xhfwSubLevelStandard" align="center" show-overflow-tooltip width="150">
        <template v-slot="scope">
          {{ filterName(scope.row.xhfwSubLevelStandard) }}
        </template>
      </el-table-column>
      <el-table-column label="单价" prop="unitPriceDisplay" align="center" show-overflow-tooltip width="150">
        <template v-slot="scope">
          {{ filterName(scope.row.unitPriceDisplay) }}
        </template>
      </el-table-column>
      <el-table-column label="件数" prop="packageCountDesc" align="center" show-overflow-tooltip width="100">
        <template v-slot="scope">
          {{ filterName(scope.row.packageCountDesc) }}
        </template>
      </el-table-column>
      <el-table-column label="合同重量(kg)" prop="contractWeightDisplay" align="center" show-overflow-tooltip width="150">
        <template v-slot="scope">
          {{ filterName(scope.row.contractWeightDisplay) }}
        </template>
      </el-table-column>
      <el-table-column label="合同货值" prop="contractAmountDisplay" align="center" show-overflow-tooltip width="150">
        <template v-slot="scope">
          {{ filterName(scope.row.contractAmountDisplay) }}
        </template>
      </el-table-column>
    </el-table>
    <div class="op-16">
      <div class="erp-form__title f16" style="margin-top: 50px">订单备注</div>
      <el-input
        v-model="formData.remark"
        type="textarea"
        placeholder="请输入订单备注"
        :maxlength="2000"
        style="margin-top: 16px"
        :autosize="{ minRows: 3 }"
      ></el-input>
    </div>
  </div>
</template>

<script>
import DictSelect from '@/components/DictSelect/DictSelect.vue';
import FormChooseMan from '@/components/ChooseMan/ChooseMan.vue';
import { erpConfirm, errorTip, successTip, hasPermission, errorMessage } from '@/utils/publicMethods';
import { httpPost } from '@/api';
export default {
  components: {
    FormChooseMan,
    // DictSelect,
  },
  props: {
    data: {
      // 获取传递过来的详情
      type: [Object],
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      totalWeight: 0, // 合同重量
      totalAmount: 0, // 合同货值
      formData: {},
      category: null,
      plantNoList: [], // 下拉厂号条件
      countryList: [],
      productPlantNo: [], // 临时存储厂号
      country: null,
      isDisbale:false
    };
  },
  created() {
    this.formData = Object.assign({}, this.data);
    this.isDisbale = this.formData.deliverStatus === 5;
    console.log("🚀 ~ file: BaseInfoForm.vue ~ line 253 ~ created ~ this.isDisbale", this.isDisbale);
  },
  methods: {
    getClientManager(ids, labelName) {
      // 获取客户经理
      this.formData.userIdManagerList = ids;
      this.formData.clientManager = labelName;
    },
    getUserDocPrincipal(ids, labelName) {
      // 设置负责人
      this.formData.userIdsDoc = ids;
      this.formData.userNameDoc = labelName;
    },
    getUserFuncPrincipal(ids, labelName) {
      // 设置负责人
      this.formData.userIdsFund = ids;
      this.formData.userNameFund = labelName;
    },
    editBaseInfo() {
      this.$emit('edit', 1);
    },
    closeOrder(status) {
      // 关闭还原订单

      erpConfirm('确定需要关闭当前订单吗？关闭后的订单不可再做修改。')
        .then(async () => {
          const res = await httpPost('/malicrm/order/closeOpenOrderQhdc', {
            id: this.data.id,
            operType: status,
            orderId: this.data.orderId,
            orderVersion: this.data.orderVersion,
            version: this.data.version,
          });
          if (res.code === 200) {
            successTip('已关闭订单');
            this.$emit('back', 1);
          } else {
            errorTip(res.message);
          }
        })
        .catch(() => {
          return false;
        });
    },
    validForm() {
      const data = this.formData;
      let status = true;
      if (status && !(data.userIdsDoc && data.userIdsDoc.length > 0)) {
        status = false;
        this.errorTip('请选择单证跟单员');
      }
      if (status && !(data.userIdsFund && data.userIdsFund.length > 0)) {
        status = false;
        this.errorTip('请选择资管跟单员');
      }
      if (status && !(data.userIdManagerList && data.userIdManagerList.length > 0)) {
        status = false;
        this.errorTip('请选择客户经理');
      }
      if(status && data.productList.length > 0){
        for(let i=0;i<data.productList.length;i++){
          let item = data.productList[i];
          if(!item.categoryName || !item.country || !item.plantNo || !item.nameCn){
            status = false;
            this.errorTip('请填写大类、国家、厂号、中文品名');
            break;
          }
        }
      }
      return status;
    },
    // 大类选择事件
    querycategoryName(ev,row){
      row.category = ev;
      row.countryName = null;
      row.plantNo = null;
      row.nameCn = null;
      row.nameEn = null;
      this.plantNoList = [];
      this.countryList = [];
    },
    async querycountry(row) {
      row.plantNo = null;
      row.nameCn = null;
      row.nameEn = null;
      this.plantNoList = [];
      this.countryList = [];
      const res = await httpPost('/malicrm/approval/queryCountry', {
        category: row.category,
        country: null,
        plantNo: null,
      });
      if (res.code === 200) {
        this.countryList = res.result;
      } else {
        errorMessage(res.message);
      }
    },
    // 厂号
    async queryPlantNo(ev,row) {
      const res = await httpPost('/malicrm/approval/queryPlantNo', {
        category: row.category,
        country: row.country,
        isAddChina: true,
        plantNo: ""
      });
      if (res.code === 200) {
        this.plantNoList = res.result;
      } else {
        errorMessage(res.message);
      }      
    },
    changePlantNo(ev,row){
      row.nameCn = null;
      row.nameEn = null;
    },
    changecountry(ev,row){
      row.plantNo = null;
      row.nameCn = null;
      row.nameEn = null;
      row.country = ev;
    },
    async queryProductLabelCn(value, cb, row) {
      // 搜索中文品名
      const res = await httpPost('/malicrm/offer/queryOfferProductLabel', {
        nameCn: value,
        category: row.category,
        country: row.country,
        plantNo: [row.plantNo],
      });
      console.log(res,1111111);
      if (res.code === 200) {
        if (res.result !== null) {
          cb(res.result);
        } else {
          const arr = [];
          cb(arr);
        }
      }
    },
    async queryProductLabelEn(value, cb, row) {
      // 搜索英文品名
      const res = await httpPost('/malicrm/offer/queryOfferProductLabel', {
        nameEn: value,
        category: row.category,
        country: row.country,
        plantNo: [row.plantNo],
      });
      if (res.code === 200) {
        if (res.result !== null) {
          cb(res.result);
        } else {
          const arr = [];
          cb(arr);
        }
      }
    },
    clearNameLabel(item) {
      item.labelUrl = null;
      item.labelId = null;
      item.nameCn = null;
      item.nameEn = null;
      item.varietyName = null;
    },
    async saveFormData() {
      if (this.validForm()) {
        const res = await httpPost('/malicrm/order/editXhfwOrder', {
          orderId: this.formData.orderId,
          userIdsDoc: this.formData.userIdsDoc,
          userIdsFund: this.formData.userIdsFund,
          version: this.formData.orderVersion,
          remark: this.formData.remark,
          userIdManagerList: this.formData.userIdManagerList,
          productList:this.formData.productList,
          status: this.formData.status,
        });
        if (res.code === 200) {
          this.successTip('保存成功');
          this.$emit('back', 1, true);
        } else {
          this.errorTip(res.message);
        }
      }
    },
    back() {
      this.$emit('back', 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.order-title__bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
}

.count-value {
  font-size: 14px;
  color: #303133;
}

.count-name {
  font-size: 14px;
  color: #606266;
}
</style>
