<template>
  <div class="order-base__warp" style="padding-bottom: 50px">
    <div class="erp-form-bar bar-sticky">
      <div class="erp-primary__btn plain" @click="finishOrder" v-if="data.status == 1 && hasPermission('erp:order:closeOpenOrderXhfw')">完结订单</div>
      <div class="erp-primary__btn plain" @click="closeOrder(1)" v-if="data.status == 4 && hasPermission('erp:order:closeOpenOrderXhfw')">关闭订单</div>
      <div
        class="erp-primary__btn plain"
        @click="reStoreOrder(data.status)"
        v-if="(data.status == 2 || data.status == 3) && hasPermission('erp:order:closeOpenOrderXhfw')"
      >
        还原
      </div>
      <div class="erp-default__btn" @click="back">返回</div>
    </div>
    <!-- 55等分布局 -->
    <el-row :gutter="0" type="flex" class="op-16">
      <el-col :span="12" style="margin-right: 80px">
        <div class="erp-form__title f16">订单信息</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view label="销售合同号" :value="data.businessContractNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="客户" :value="data.customerName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="上游名称" :value="data.xhfwUpstreamName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="订单状态" :value="data.statusDisplay"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="冷库" :value="data.storageName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="柜号" :value="data.containerNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="保证金比例(%)" :value="data.depositRate"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="资金方" :value="data.fundingShortName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="抬头" :value="data.consigneeShortName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="单证跟单员" :value="data.userNameDoc"></erp-view>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <div class="claim_company" v-if="data.arList && data.arList.length > 0">
      <!--应收项目展示-->
      <div style="margin-top: 50px" class="order-title__bar op-16">
        <div class="erp-form__title f16">应收项目</div>
      </div>
      <el-table :data="data.arList" :show-summary="data.arList.length > 1" :summary-method="summaryFn" border class="egrid erp-table__list erp-view__table">
        <el-table-column label="费用类型" prop="feeTypeDisplay" align="center" show-overflow-tooltip width="357">
          <template v-slot="scope">
            <div>{{ filterName(scope.row.feeTypeDisplay) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="金额(人民币)" prop="amountRmb" align="center" show-overflow-tooltip width="353">
          <template v-slot="scope">
            <div>{{ filterName(scope.row.amountRmbDisplay) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark" align="center" show-overflow-tooltip>
          <template v-slot="scope">
            <div>{{ filterName(scope.row.remark) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="凭据文件" class-name="no-pd" align="center" show-overflow-tooltip width="140">
          <template v-slot="scope">
            <tableFileContent
              :readOnly="true"
              :fileUrlsArray="scope.row.fileVoList"
              key-name="orderArapFileName"
              key-url="orderArapFileUrl"
              key-id="orderArapFileId"
            >
            </tableFileContent>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="claim_company" v-if="data.infundList && data.infundList.length > 0">
      <!--实际收款记录展示-->
      <div style="margin-top: 50px" class="order-title__bar op-16">
        <div class="erp-form__title f16">实际收款记录</div>
      </div>

      <el-table
        :data="data.infundList"
        :show-summary="data.infundList.length > 1"
        :summary-method="fundListFn"
        border
        class="egrid erp-table__list erp-view__table"
      >
        <el-table-column label="费用类型" prop="infundTypeDisplay" align="center" show-overflow-tooltip width="220">
          <template v-slot="scope">
            <div>{{ filterName(scope.row.infundTypeDisplay) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="金额(人民币)" prop="actualAmount" align="center" show-overflow-tooltip width="250">
          <template v-slot="scope">
            <div>{{ filterName(scope.row.actualAmountDisplay) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="收款日期" prop="transactionTime" align="center" show-overflow-tooltip width="240">
          <template v-slot="scope">
            <div>{{ filterName(scope.row.transactionTime) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark" align="center" show-overflow-tooltip>
          <template v-slot="scope">
            <div>{{ filterName(scope.row.remark) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="凭据文件" class-name="no-pd" align="center" show-overflow-tooltip width="140">
          <template v-slot="scope">
            <tableFileContent
              :readOnly="true"
              :fileUrlsArray="scope.row.fileVoList"
              key-name="orderArapFileName"
              key-url="orderArapFileUrl"
              key-id="orderArapFileId"
            >
            </tableFileContent>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import tableFileContent from '@/components/TableFileContent/TableFileContent.vue';
import { erpConfirm, errorTip, getRouterParams, successTip } from '@/utils/publicMethods';
import { httpPost } from '@/api';
export default {
  components: {
    tableFileContent,
  },
  props: {
    data: {
      // 获取传递过来的详情
      type: [Object],
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      showSummary: true,
      showfundList: true, //
      showpaymentList: true, //
      containerTypeDisplay: 0,
      showapList: true, // 应付项目
      totalWeight: 0, // 实际净重合计
      totalAmount: 0, // 实际货值
    };
  },
  mounted() {
    this.getinit();
  },
  methods: {
    finishOrder() {
      erpConfirm('确定需要完结当前订单吗？完结后的订单不可再做修改。').then(async () => {
        const res = await httpPost('/malicrm/order/finishOrder', {
          orderId: this.data.orderId,
        });
        if (res.code === 200) {
          this.$emit('back', 1);
          successTip('已完结订单');
        } else {
          errorTip(res.message);
        }
      });
    },
    reOpenOrder() {
      erpConfirm('确定要恢复当前订单状态为进行中吗？').then(async () => {
        const res = await httpPost('/malicrm/order/reOpenOrder', {
          orderId: this.data.orderId,
        });
        if (res.code === 200) {
          this.$emit('back', 1);
          successTip('已恢复订单');
        } else {
          errorTip(res.message);
        }
      });
    },
    reStoreOrder(status) {
      // 还原判断
      erpConfirm(status === 2 ? '确定要将当前订单还原为“已取消”的状态吗？' : '确定要将当前订单还原为“进行中”的状态吗？')
        .then(async () => {
          const url = status === 2 ? '/malicrm/order/closeOpenOrderQhdc' : '/malicrm/order/reOpenOrder';
          const data = {
            id: this.data.id,
            operType: status,
            orderId: this.data.orderId,
            orderVersion: this.data.orderVersion,
            version: this.data.version,
          };
          const res = await httpPost(url, data);
          if (res.code === 200) {
            successTip('已还原订单');
            this.$emit('back', 1);
          } else {
            errorTip(res.message);
          }
        })
        .catch(() => {
          return false;
        });
    },
    closeOrder(status) {
      // 关闭还原订单
      erpConfirm('确定需要关闭当前订单吗？关闭后的订单不可再做修改。')
        .then(async () => {
          const res = await httpPost('/malicrm/order/closeOpenOrderQhdc', {
            id: this.data.id,
            operType: status,
            orderId: this.data.orderId,
            orderVersion: this.data.orderVersion,
            version: this.data.version,
          });
          if (res.code === 200) {
            successTip('已关闭订单');
            this.$emit('back', 1);
          } else {
            errorTip(res.message);
          }
        })
        .catch(() => {
          return false;
        });
    },
    getinit() {
      this.containerTypeDisplay = 0;
      let containerTypeDisplay = this.data.productList.reduce((total, item) => {
        const instoreCount = Number(item.actualAmount);
        return total + instoreCount;
      }, 0);
      containerTypeDisplay = this._formatFloat(containerTypeDisplay, 2);
      this.containerTypeDisplay = this._thousandBitSeparator(containerTypeDisplay);
    },

    // 应收项目
    summaryFn() {
      // 自定义过显示合计的方法
      const summaryColumns = ['合计', '', '', '', '', '', ''];
      const data = this.data.arList;
      let amountRmb = data.reduce((total, item) => {
        const instoreCount = Number(item.amountRmb);
        return total + instoreCount;
      }, 0);
      amountRmb = this._formatFloat(amountRmb, 2);
      summaryColumns[1] = this._thousandBitSeparator(amountRmb);
      const isNullArray = data.filter((v) => !!v.amountRmbAfterModify); // 判断是不是全空
      if (isNullArray.length > 0) {
        // 如果有一条不为空的那么取金额
        let totalmodifyRmb = data.reduce((total, item) => {
          const price = item.amountRmbAfterModify ? Number(item.amountRmbAfterModify) : Number(item.amountRmb);
          return total + price;
        }, 0);
        totalmodifyRmb = this._formatFloat(totalmodifyRmb, 2);
        summaryColumns[4] = this._thousandBitSeparator(totalmodifyRmb);
      }
      return summaryColumns;
    },
    // 实际付款记录
    paymentListFn() {
      // 自定义过显示合计的方法
      const summaryColumns = ['合计', '', '', '', '', '', ''];
      const data = this.data.paymentList;
      let actualPayAmount = data.reduce((total, item) => {
        const instoreCount = item.paymentTypeDisplay === '供应商退款' ? Number(-item.actualPayAmount) : Number(item.actualPayAmount);
        return total + instoreCount;
      }, 0);
      actualPayAmount = this._formatFloat(actualPayAmount, 2);
      summaryColumns[1] = this._thousandBitSeparator(actualPayAmount);

      return summaryColumns;
    },
    // 应付项目
    apListFn() {
      // 自定义过显示合计的方法
      const summaryColumns = ['合计', '', '', '', '', ''];
      const data = this.data.apList;
      let amountRmb = data.reduce((total, item) => {
        const instoreCount = Number(item.amountRmb);
        return total + instoreCount;
      }, 0);
      amountRmb = this._formatFloat(amountRmb, 2);
      summaryColumns[1] = this._thousandBitSeparator(amountRmb);

      return summaryColumns;
    },
    // 实际收款记录
    fundListFn() {
      // 自定义过显示合计的方法
      const summaryColumns = ['合计', '', '', '', ''];
      const data = this.data.infundList;
      let actualAmount = data.reduce((total, item) => {
        const instoreCount = item.infundTypeDisplay === '客户退款' ? Number(-item.actualAmount) : Number(item.actualAmount);
        return total + instoreCount;
      }, 0);
      actualAmount = this._formatFloat(actualAmount, 2);
      summaryColumns[1] = this._thousandBitSeparator(actualAmount);

      return summaryColumns;
    },
    getAmount() {
      const productList = this.data.productList;
      let totalWeight = productList.reduce((total, item) => {
        let value = item.actualWeight;
        if (item.actualWeight === null || item.actualWeight === '') {
          value = 0;
        }
        return total + Number(value);
      }, 0);
      let totalAmount = productList.reduce((total, item) => {
        let value = Number(item.actualWeight) * Number(item.unitPrice);
        if (item.actualAmount === null || item.actualAmount === '' || item.unitPrice === null || item.unitPrice === '') {
          value = 0;
        }
        return total + Number(value);
      }, 0);
      totalWeight = totalWeight.toFixed(2);
      totalAmount = totalAmount.toFixed(2);
      this.totalWeight = this._thousandBitSeparator(totalWeight);
      this.totalAmount = this._thousandBitSeparator(totalAmount);
    },

    clickTag(url) {
      if (url) {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.click();
      }
    },
    back() {
      const { orderId, name, version } = getRouterParams('OrderSpotDetail');
      this.$router.push({
        name: name,
        params: {
          id: orderId,
          version: version,
        },
      });
    },
    editBaseInfo() {
      // 通知tab页切换显示 编辑页面
      this.$emit('edit', 1);
    },
  },
  created() {
    this.getAmount(); // 获取合计值
  },
};
</script>

<style lang="scss" scoped>
.order-title__bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
}
.count-value {
  font-size: 14px;
  color: #303133;
}
.count-name {
  font-size: 14px;
  color: #606266;
}
</style>
