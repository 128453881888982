<template>
  <div class="order-base__warp" style="padding-bottom: 50px">
    <div class="erp-form-bar bar-sticky">
      <div class="erp-default__btn" @click="back">返回</div>
    </div>
    <el-row type="flex" class="op-16" :gutter="80">
      <el-col :span="12">
        <div class="erp-form__title f16">订单信息</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view label="销售合同号" :value="data.businessContractNo" type="edit">
              <el-input v-model="formData.businessContractNo" :disabled="true"></el-input>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="客户" :value="data.customerName" type="edit">
              <el-input v-model="formData.customerName" :disabled="true"></el-input>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="上游名称" :value="data.xhfwUpstreamName" type="edit">
              <el-input :model-value="formData.xhfwUpstreamName ? formData.xhfwUpstreamName : '-'" :disabled="true"></el-input>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="订单状态" :value="data.statusDisplay" type="edit">
              <el-input v-model="formData.statusDisplay" :disabled="true"></el-input>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="冷库" :value="data.storageName" type="edit">
              <el-input :model-value="formData.storageName ? formData.storageName : '-'" :disabled="true"></el-input>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="柜号" :value="data.containerNo" type="edit">
              <el-input :model-value="formData.containerNo ? formData.containerNo : '-'" :disabled="true"></el-input>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="保证金比例(%)" :value="data.depositRate" type="edit">
              <el-input v-model="formData.depositRate" :disabled="true"></el-input>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="资金方" :value="data.fundingShortName" type="edit">
              <el-input :model-value="formData.fundingShortName ? formData.fundingShortName : '-'" :disabled="true"></el-input>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="抬头" :value="data.consigneeShortName" type="edit">
              <el-input :model-value="formData.consigneeShortName ? formData.consigneeShortName : '-'" :disabled="true"></el-input>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="单证跟单员" type="edit">
              <el-input v-model="formData.userNameDoc" :disabled="true"></el-input>
            </erp-view>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!--订单证件展示-->
    <div style="margin-top: 50px; margin-bottom: 12px" class="order-title__bar op-16">
      <div class="erp-form__title f16">证件</div>
    </div>
    <el-table :data="attachmentList" border class="egrid erp-table__list erp-view__table">
      <el-table-column label="证件类型" prop="attachmentTypeDisplay" align="center" show-overflow-tooltip width="240"></el-table-column>
      <el-table-column label="文件名" prop="name" align="center" show-overflow-tooltip width="940">
        <template v-slot="scope">
          <div class="card-preview" style="justify-content: center">
            <span>{{ scope.row.name }}</span>
            <img src="../../../../../assets/images/preview__zoom.png" alt @click="$showFiles(scope.row.name, scope.row.url)" />
            <img src="../../../../../assets/images/preview_download.png" alt @click="$showFiles(scope.row.name, scope.row.url, false)" />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="上传人" prop="creator" align="center" show-overflow-tooltip width="180"></el-table-column>
      <el-table-column label="上传时间" prop="createdTime" align="center" show-overflow-tooltip width="180"></el-table-column>
      <el-table-column fixed="right" label="操作" align="center">
        <template v-slot="scope">
          <span class="list-del" @click="deleteCard(scope)">
            <img src="../../../../../assets/images/list-del.png" alt />
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: right; margin-top: 10px">
      <Pagination
        ref="pcPagination"
        url="/malicrm/order/queryOrderQhdcAttachmentListWithPage"
        :queryData="dataQueryForm"
        @change="queryCallBackFn"
        :showTotal="2"
        :isSimple="false"
      >
      </Pagination>
    </div>
    <div class="op-16">
      <div class="erp-form__title f16" style="margin: 30px 0 12px 0">上传证件</div>
      <div class="card-upload-warp">
        <div style="display: flex; margin-bottom: 20px">
          <el-select v-model="attachmentType" placeholder="请选择证件类型" value-key="value" style="width: 160px" size="small">
            <el-option
              v-for="item in Options.qhdc_order_attachment_type"
              :key="item.value"
              :label="item.label"
              :value="item"
              :disabled="item.label === 'SC/PI'"
            ></el-option>
          </el-select>
          <div class="erp-primary__btn" :class="{ disabled: formAttachmentList.length <= 0 }" @click.stop="saveFormData" style="width: 80px; margin-left: 20px">
            上传
          </div>
        </div>
        <SingleFileContent :fileType="1" :fromType="1" @success="getContractUrls" :max="100" :customData="attachmentType" customType="card">
        </SingleFileContent>
      </div>
    </div>
  </div>
</template>

<script>
import SingleFileContent from '@/components/FileContent/FileContent.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import { store } from '@/store';
import { erpConfirm } from '@/utils/publicMethods';
import { httpPost } from '@/api';

export default {
  components: {
    SingleFileContent,
    Pagination,
  },
  props: {
    data: {
      // 获取传递过来的详情
      type: [Object],
      default: function () {
        return {
          id: 0,
          ladingBillNo: null, // 提单号
          orderId: 0,
          orderVersion: 0,
          shippingEndDate: null, // 到港日
          supplierContractNo: null, // 供应商合同号
          isNeedJr: '', // 是否提供供应链金融服务
          version: 0,
        };
      },
    },
  },
  data() {
    return {
      fromType: 1, // 组件展示类型,
      Options: {
        // 字典下拉数据初始化

        qhdc_order_attachment_type: store.state.dict.options.qhdc_order_attachment_type,
      },
      attachmentType: {
        // 选择类型
        label: null,
        value: null,
      },
      formData: {
        id: 0,
        ladingBillNo: null, // 提单号
        orderId: 0,
        orderVersion: 0,
        shippingEndDate: null, // 到港日
        supplierContractNo: null, // 供应商合同号
        attachmentType: null, // 证件类型
        name: null,
        url: null,
        version: 0,
      },
      dataQueryForm: {
        pageSize: 10,
        pageNum: 1,
        orderId: this.data.orderId,
      },
      attachmentList: [],
      isShow: false,
      formAttachmentList: [], // 上传保存用上传列表
    };
  },
  created() {
    this.formData = Object.assign({}, this.data);
  },
  methods: {
    queryCallBackFn(res) {
      console.log(res);
      this.attachmentList = res;
    },
    isChangeAttachmentType(e) {
      // 控制判断是否选择证件类型
      if (this.attachmentType.value && this.attachmentType.value !== '') {
        return false;
      } else {
        this.errorTip('请选择证件类型');
        e.stopPropagation();
      }
    },
    deleteCard(scope) {
      erpConfirm('确定删除该证件吗？').then(async () => {
        const res = await httpPost('/malicrm/order/deleteOrderQhdcAttachment', {
          id: scope.row.id,
          version: scope.row.version,
        });
        if (res.code === 200) {
          this.$refs.pcPagination.refashTable(true);
        } else {
          this.errorTip(res.message);
        }
      });
    },
    getContractUrls(data) {
      // 获取到上传的文件
      const attachmentList = [];
      data.forEach((v) => {
        const data = {
          attachmentType: v.customData.value,
          name: v.name,
          url: v.url,
        };
        attachmentList.push(data);
      });
      console.log(data);
      this.formAttachmentList = attachmentList;
    },
    /**
     * 保存编辑信息
     */
    async saveFormData() {
      if (this.formAttachmentList && this.formAttachmentList.length <= 0) {
        return false;
      } else {
        const list = this.attachmentList.concat(this.formAttachmentList);
        const res = await httpPost('/malicrm/order/uploadOrderQhdcAttachment', {
          attachmentList: list,
          isNeedJr: this.data.isNeedJr,
          orderId: this.data.orderId,
        });
        if (res.code === 200) {
          this.back();
        } else {
          this.errorTip(res.message);
        }
      }
    },
    back() {
      // 返回详情页
      this.$emit('back', 4);
    },
  },
};
</script>
<style lang="scss" scoped>
.card-upload__btns {
  display: flex;
  align-items: center;
  > * {
    margin-right: 8px;
  }
}
.card-upload-warp {
  border: 1px solid #dcdfe6;
  padding: 20px 17px;
  margin-top: 16px;
}
</style>
