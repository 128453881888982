<template>
  <div>
    <!-- 基础信息是tab页切换 -->
    <div class="order-tabs__warp" v-if="loadStatus">
      <el-tabs v-model="activeName" class="order-tabs">
        <el-tab-pane label="基本信息" name="1" v-if="status.includes(1)">
          <!-- 基础信息查看 -->
          <base-info :data="detailsData" @edit="tabEdit" v-if="!isEdit" @back="editBack"></base-info>
          <base-info-form :data="detailsData" @back="editBack" v-else></base-info-form>
        </el-tab-pane>
        <el-tab-pane label="收付栏" name="3" v-if="status.includes(3) && detailsData.isShowArap && hasPermission('erp:order:queryOrderXhfwArap')" lazy>
          <payment-column :data="detailsData" @back="editBack"></payment-column>
        </el-tab-pane>
        <el-tab-pane label="收款栏" name="5" v-if="status.includes(5) && hasPermission('erp:order:queryOrderXhfwAr')" lazy>
          <gathering-bar :data="detailsData" @edit="tabEdit" @back="editBack"> </gathering-bar>
        </el-tab-pane>
        <el-tab-pane label="证件栏" name="4" v-if="status.includes(4)" lazy>
          <card-info :data="detailsData" @edit="tabEdit" v-if="!isEdit" @back="editBack"></card-info>
          <card-info-from :data="detailsData" @back="editBack" v-else></card-info-from>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import BaseInfo from './components/BaseInfo';
import BaseInfoForm from './components/BaseInfoForm';
import PaymentColumn from './components/PaymentColumn';
import GatheringBar from './components/GatheringBar';
import CardInfo from './components/CardInfo';
import CardInfoFrom from './components/CardInfoForm.vue';
import { getRouterParams } from '@/utils/publicMethods';
import { httpGet } from '@/api';
export default {
  components: {
    BaseInfo,
    PaymentColumn,
    BaseInfoForm,
    GatheringBar,
    CardInfo,
    CardInfoFrom,
  },
  data() {
    return {
      loadStatus: false,
      status: [1, 2, 3, 4, 5], // 显示值控制参数 默认给大点的数字
      activeName: '1',
      isEdit: false, // 是否进入编辑态
      statusEdit: false, // 是否进入编辑态
      detailsData: {
        // 订单详情信息
        isShowArap: false,
        agencyName: null,
        businessContractNo: null,
        category: null,
        categoryDisplay: null,
        clearanceProgress: {
          description: null,
          id: 0,
          isSettled: false,
          isSettledDisplay: null,
          lastOperator: null,
          performDate: null,
          updateTime: null,
          version: 0,
        },
        consigneeShortName: null,
        containerNo: null,
        containerType: null,
        containerTypeDisplay: null,
        copyDocProgress: {
          description: null,
          id: 0,
          isSettled: false,
          isSettledDisplay: null,
          lastOperator: null,
          performDate: null,
          updateTime: null,
          version: 0,
        },
        country: null,
        countryDisplay: null,
        customerId: 0,
        customerName: null,
        contractPrincipalName: null,
        declarationKeyElements: null,
        declarationProgress: {
          description: null,
          id: 0,
          isSettled: false,
          isSettledDisplay: null,
          lastOperator: null,
          performDate: null,
          updateTime: null,
          version: 0,
        },
        depositRate: 0,
        depositRateDisplay: null,
        id: 0,
        incoterm: null,
        incotermDisplay: null,
        isNeedJr: '', // 是否提供供应链金融服务
        ladingBillNo: null,
        orderId: 0,
        orderVersion: 0,
        originalDocProgress: {
          description: null,
          id: 0,
          isSettled: false,
          isSettledDisplay: null,
          lastOperator: null,
          performDate: null,
          updateTime: null,
          version: 0,
        },
        portName: null,
        produceDate: null,
        productList: [
          {
            actualAmount: 0,
            actualAmountDisplay: null,
            actualWeight: 0,
            actualWeightDisplay: null,
            category: null,
            contractAmount: 0,
            contractAmountDisplay: null,
            contractWeight: 0,
            contractWeightDisplay: null,
            country: null,
            elecInfoStatus: null,
            elecInfoStatusDisplay: null,
            healthCertNo: null,
            labelId: 0,
            nameCn: null,
            nameEn: null,
            packageCount: 0,
            packageCountDisplay: null,
            plantNo: null,
            tradeCurrency: null,
            tradeCurrencyDisplay: null,
            unitPrice: 0,
            unitPriceDisplay: null,
            varietyName: null,
          },
        ],
        remark: null,
        returnBoxProgress: {
          description: null,
          id: 0,
          isSettled: false,
          isSettledDisplay: null,
          lastOperator: null,
          performDate: null,
          updateTime: null,
          version: 0,
        },
        shippingEndDate: null,
        shippingInfo: null,
        shippingStartDate: null,
        status: null,
        statusDisplay: null,
        supplierContractNo: null,
        supplierId: 0,
        supplierName: null,
        userIdDoc: [0],
        userIdFund: [0],
        userIdsDoc: [],
        userIdsFund: [],
        userNameDoc: null,
        userNameFund: null,
        version: 0,
      },
    };
  },
  methods: {
    tabEdit(status) {
      // 切换编辑，可以通用
      this.status = [status];
      this.isEdit = true;
    },
    back() {
      // 详细信息返回
      this.status = [1, 2, 3, 4, 5];
      this.isEdit = false;
    },
    /**
     * 获取订单详情
     * @param orderId 订单id
     * @param name 判断是否刷新显示tab true 刷新 false不刷新
     */
    async getOrderDetails(orderId, status) {
      // 判断是否状态列表刷新
      const res = await httpGet(`/malicrm/order/queryOrderXhfwDetailById/${orderId}`);
      if (res.code === 200) {
        this.detailsData = Object.freeze(res.result);
        this.status = [1, 2, 3, 4, 5];
        this.loadStatus = true;
        if (status) {
          this.isEdit = false;
        }
      } else {
        this.errorTip(res.message);
        this.status = [1, 2, 3, 4, 5];
        this.loadStatus = true;
        if (status) {
          this.isEdit = false;
        }
      }
    },
    editBack(status, reach) {
      // 表单编辑返回，可以通用
      if (status === 2 || status === 1 || status === 4) {
        // 这个方法不要改，其他模块全是一致的
        this.status = [1, 2, 3, 4, 5];
        this.getOrderDetails(this.detailsData.orderId, true);
      }
      if (reach) {
        const { orderId } = getRouterParams('OrderSpotDetail');
        this.getOrderDetails(orderId, false);
      }
    },
  },
  created() {
    const { orderId } = getRouterParams('OrderSpotDetail');
    this.getOrderDetails(orderId);
  },
};
</script>

<style lang="scss" scoped></style>
